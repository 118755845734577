import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  ActiveSessionData,
  ActiveSessionPointDto,
  DemandShiftingData,
  DemandShiftingPointDto,
  EnergyConsumptionResponse,
} from "../models/chart";

export default class DashboardStore {

  demandShiftingUsage: DemandShiftingData = {
    pointDtos: [],
  };

  activeSessions: ActiveSessionData = {
    pointDtos: [],
  }

  energyComparison: EnergyConsumptionResponse = {
    realEnergyConsumed: [],
    estimatedEnergyConsumed: [],
    price: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  getEnergyComparison = async (
    startDate: string = "",
    endDate: string = ""
  ) => {
    try {
      const data = await agent.Chart.energyComparison(startDate, endDate);
      runInAction(() => {
        this.energyComparison = {
          realEnergyConsumed: data.realEnergyConsumed.map((el: { dateHour: string; value: number; }) => {
            let date = new Date(el.dateHour.slice(-1) === 'Z' ? el.dateHour : el.dateHour + 'Z');
            date.setMinutes(30, 0, 0);
            return { dateHour: date.toISOString(), value: el.value }
          }),
          estimatedEnergyConsumed: data.estimatedEnergyConsumed.map((el: { dateHour: string; value: number; }) => {
            let date = new Date(el.dateHour.slice(-1) === 'Z' ? el.dateHour : el.dateHour + 'Z');
            date.setMinutes(30, 0, 0);
            return { dateHour: date.toISOString(), value: el.value }
          }),
          price: data.price.map((el: { dateHour: string; value: number; }) => {
            let date = new Date(el.dateHour.slice(-1) === 'Z' ? el.dateHour : el.dateHour + 'Z');
            date.setMinutes(30, 0, 0);
            return { dateHour: date.toISOString(), value: el.value }
          }),
        };
      });
    } catch (error) {
      console.log("Failed to fetch energy comparison data: ", error);
    }
  };

  getDemandShiftingUsage = async (
    startDate: string = "",
    endDate: string = ""
  ) => {
    try {
      const data: DemandShiftingData = await agent.Chart.demandShiftingUsage(
        startDate,
        endDate
      );
      data.pointDtos.forEach((el: DemandShiftingPointDto) => {
        let date = new Date(el.date.slice(-1) === 'Z' ? el.date : el.date + 'Z');
        date.setMinutes(30, 0, 0);
        el.date = date.toISOString();
      });
      console.log(data)
      runInAction(() => {
        this.demandShiftingUsage = data;
      });
    } catch (error) {
      console.log("Failed to fetch demand shifting usage data: ", error);
    }
  };

  getActiveSessions = async (
    startDate: string = "",
    endDate: string = ""
  ) => {
    try {
      const data: ActiveSessionData = await agent.Chart.activeSessions(
        startDate,
        endDate
      );
      data.pointDtos.forEach((el: ActiveSessionPointDto) => {
        let date = new Date(el.date.slice(-1) === 'Z' ? el.date : el.date + 'Z');
        date.setMinutes(30, 0, 0);
        el.date = date.toISOString();
      });
      console.log(data)
      runInAction(() => {
        this.activeSessions = data;
      });
    } catch (error) {
      console.log("Failed to fetch active sessions data: ", error);
    }
  };
}
