import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useState } from "react";
import { CSVdownload } from "../../../../components/CSVdownload/CSVdownload";
import { downloadCSV } from "../../../../utils/csv/downloadCsv";
import { roundOnFixedDecimals } from "../../../../utils/numbers/numbersHelper";
import { xAxisLabels } from "./XAxisLabels";

interface Props {
  date: string;
  xValues?: string[];
  yValues: { session: number, demandShifting: number, time: string }[];
}

const DashboardDemandChart = ({
  date,
  xValues = xAxisLabels,
  yValues,
}: Props) => {
  const [optionsHovered, setOptionsHovered] = useState(false);


  const data = {
    labels: xValues,
    datasets: [
      {
        data: yValues,
        label: "Demand Shifting Time",
        backgroundColor: "#DA3333",
        categoryPercentage: 0.9,
        borderRadius: 4,
        parsing: {
          xAxisKey: 'time',
          yAxisKey: 'demandShifting',
        },
      },
      {
        data: yValues,
        label: "Total Session Time",
        backgroundColor: "#D7D7D7",
        categoryPercentage: 0.9,
        borderRadius: 4,
        parsing: {
          xAxisKey: 'time',
          yAxisKey: 'session',
        },
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        dragData: false,
        stacked: true,
        grid: {
          color: "#1C1C1C0D",
          offset: false,
          beginAtZero: true
        },
        ticks: {
          callback: function (val: any, index: number) {
            return index % 2 === 0 ? xAxisLabels[index] : '';
          },
          maxRotation: 0,
          minRotation: 0
        },
      },
      y: {
        dragData: false,
        max: 100,
        min: 1,
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 20,
          callback: function (tickValue: string | number) {
            if (typeof tickValue === "number") {
              if (Math.floor(tickValue) === tickValue) {
                return tickValue;
              }
            }
            return null;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        onHover: function (e: any) {
          e.target.style.cursor = "pointer";
        },
      },
      tooltip: {
        displayColors: false,
        padding: {
          top: 6,
          bottom: 6,
          left: 8,
          right: 8,
        },
        callbacks: {
          title: () => "",
          label: function (tooltipItem: any) {
            if (tooltipItem.dataset.label === "Total Session Time") {
              const val = tooltipItem.parsed?._stacks?.y[0] || 0;
              return `${roundOnFixedDecimals(tooltipItem.raw.session - val, 0)}%`;
            } else {
              return `${roundOnFixedDecimals(tooltipItem.raw.demandShifting, 0)}%`;
            }
          },
          labelColor: function (context: any) {
            const label = context.dataset.label;
            if (label === "Energy Dispersed") {
              return {
                backgroundColor: "#DA3333",
                borderColor: "#DA3333",
              };
            } else if (label === "Additional Energy") {
              return {
                backgroundColor: "#515151",
                borderColor: "#515151",
              };
            }
            return {
              backgroundColor: "#DA3333",
              borderColor: "#DA3333",
            };
          },
          backgroundColor: function (tooltipItem: any) {
            const label = tooltipItem[0].dataset.label;
            if (label === "Energy Dispersed") {
              return "#DA3333";
            } else if (label === "Additional Energy") {
              return "#515151";
            }
            return "#DA3333";
          },
        },
      },
    },
    onHover: (event: any, activeElements: any) => {
      (event?.native?.target as HTMLElement).style.cursor =
        activeElements?.length > 0 ? "pointer" : "auto";
    },
  };

  const handleDownloadCSV = async () => {
    try {
      const zip = (y: number[], x: string[]) =>
        y.map((yVal, idx) => ({
          demandShiftingTimePercentage: yVal,
          date: date,
          time: x[idx],
        }));
      downloadCSV(zip(yValues.map(el => el.demandShifting), yValues.map(el => el.time)), "DemandShiftingUsage-" + date);
    } catch (err) {
      console.log("Error while mapping data for download csv: ", err);
    }
  };

  return (
    <div className="flex flex-col gap-6 w-full">
      <div className="flex justify-between items-center w-[100%]">
        <div className="text-[14px] font-semibold text-darkGreen">
          Demand shifting usage %
        </div>
        <div className="flex items-center gap-5 relative">
          <div className="flex gap-2">
            <div className="flex text-[12px] text-darkGreen">
              <img alt="" src="/assets/svg/chart-dots/red.svg" />
              Sessions using demand shifting
            </div>
            <div className="flex text-[12px] text-[#515151]">
              <img alt="" src="/assets/svg/chart-dots/grayDash.svg" />
              Sessions not using demand shifting
            </div>
          </div>
          <div className="relative cursor-pointer">
            <img
              src={
                optionsHovered
                  ? "/assets/svg/dots/dotsActive.svg"
                  : "/assets/svg/dots/dots.svg"
              }
              alt=""
              className="size-7"
              onClick={() => setOptionsHovered((prev) => !prev)}
            />
            {optionsHovered && (
              <div
                style={{ boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.07)" }}
                className="absolute top-7 right-0"
              >
                <CSVdownload handleDownloadCSV={handleDownloadCSV} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full pl-4 pr-10">
        <Bar data={data} options={options} className="h-[240px] w-full" />
      </div>
    </div>
  );
};

export default DashboardDemandChart;
