import L from "leaflet";

export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZHVqbWExNjA4IiwiYSI6ImNscnlxZTRvMzFtaHYyaW11ZDQ1cTRhczYifQ.NNWHqyeKHUH06Pw4-EegTQ";

export const MAP_URL = `https://api.mapbox.com/styles/v1/dujma1608/clx4nwfr4001101pj2bsldsxo/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`;

export const fetchCoordinates = async (address: string) => {
  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
    );
    const data = await response.json();
    if (data.features && data.features.length > 0) {
      const coordinates = data.features[0]?.geometry?.coordinates;
      if (coordinates && coordinates.length >= 2) {
        const lon = coordinates[0];
        const lat = coordinates[1];
        return { lat, lon };
      }
    }
    throw new Error("Coordinates not found for the address");
  } catch (error) {
    console.error("Error fetching coordinates:", error);
    return null;
  }
};

export const CustomMarkerIcon = (number: number, zoomLevel: number) => {
  const size = Math.max(100 * (zoomLevel / 12), 45); // Adjust size based on zoom level
  return new L.DivIcon({
    className: "custom-marker-icon",
    iconSize: [45, 50],
    iconAnchor: [size / 2, size / 2],
    html: `
      <div style="box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10);"
           class="flex justify-center items-center rounded-full border border-[3px] border-[#E6F0FE] hover:bg-light-green bg-[#008480] w-[42px] h-[42px] p-3 cursor-pointer">
        <p style="font-size: 19px; font-weight: bold; color: #E6F0FE;">${number}</p>
      </div>`,
  });
};

export const NewMarkerIcon = (number: number = 0, zoomLevel: number) => {
  const size = Math.max(100 * (zoomLevel / 10), 45);
  return new L.DivIcon({
    className: "custom-marker-icon",
    iconSize: [45, 50],
    iconAnchor: [size / 5, size / 2],
    html: `
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 8px;">
        <div
          style="box-shadow: 0px 14px 29.6px 0px rgba(0, 0, 0, 0.05); text-align: center; padding: 12px 10px; width: 200px; border-radius: 10px; background-color: #FFF; font-size: 10px; font-weight: 500; color: #004C41;"
        >
          Move pin to new, more accurate position
        </div>
        <div
          style="box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10); display: flex; justify-content: center; align-items: center; border-radius: 50%; border: 3px solid #E6F0FE; background-color: #008480; width: 45px; height: 45px; padding: 3px; cursor: pointer;"
        >
          <p style="font-size: 20px; font-weight: 600; color: #E6F0FE;">${number}</p>
        </div>
      </div>`,
  });
};
