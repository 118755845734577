interface Props {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const CalendarButton = ({ label, isActive, onClick }: Props) => (
  <button
    className={`flex justify-center items-center text-[12px] font-medium whitespace-nowrap border-2 border-input-border rounded-[10px] h-[38px] w-[90px] px-[10px] py-[8px] ${isActive ? "bg-[#008480] text-white border-none" : ""
      }`}
    onClick={onClick}
  >
    {label}
  </button>
);

export default CalendarButton;
