import { useEffect, useState } from "react";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import {
  adjustDate,
  convertUTCDateTimeToLocale,
  formatDateLocale,
  formatDemandDateLocale,
  formatDemandShiftingTime,
} from "../../../utils/time/formatDateLocale";
import { useStore } from "../../../app/stores/store";
import PreloadMap from "../../../components/Map/PreloadMap";
import DateButton from "../../../components/DateButton/DateButton";
import { observer } from "mobx-react-lite";
import DashboardDemandChart from "./Charts/DashboardDemandChart";
import DashboardEnergy from "./Charts/DashboardEnergy";
import DemandDateSelect from "../../../components/Calendar/DemandDateSelect";
import { toJS } from "mobx";
import { roundOnFixedDecimals } from "../../../utils/numbers/numbersHelper";
import DashboardEstimatedEnergy from "./Charts/DashboardEstimatedEnergy";
import { ActiveSessionData, DemandShiftingData } from "../../../app/models/chart";
import DashboardActiveSessionsChart from "./Charts/DashboardActiveSessionsChart";
import { xAxisLabelsHalfHour } from "./Charts/XAxisLabels";

type Calendar = {
  startDate: string | null;
  endDate: string | null;
};

const DashboardOverview = observer(() => {
  const { dashboardStore, chargerStore } = useStore();
  const { getEnergyComparison, energyComparison, demandShiftingUsage, activeSessions } =
    dashboardStore;
  const [showDateModal, setShowDateModal] = useState(false);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);
  const yesterdayLocal = yesterday
    .toLocaleString("sv-SE", { timeZoneName: "short" })
    .split(" ")[0]; // "sv-SE" gives YYYY-MM-DD format

  const [calendar, setCalendar] = useState<Calendar>({
    startDate: yesterdayLocal,
    endDate: yesterdayLocal,
  });

  const handleSelectedDate = (value: any) => {
    const start = new Date(value);
    start.setHours(0, 0, 0, 0);
    setCalendar({ startDate: start.toISOString(), endDate: start.toISOString() });
  };

  const formatPriceData = (value: any) => {
    return value.map(
      (item: { dateHour: string; value: any }) => {
        const formattedTime = formatDemandShiftingTime(item.dateHour);
        return {
          dateHour: formattedTime,
          value: item.value,
        };
      }
    );
  };

  const formatDemandShiftingData = (demandShiftingData: DemandShiftingData) => {
    const existingData = demandShiftingData.pointDtos.map(point => {
      const formattedTime = formatDemandShiftingTime(point.date);
      const totalSessionTime = 100;
      const demandShiftingTime = point.totalSessionTime ? Number(roundOnFixedDecimals((point.totalDemandShiftingTime / point.totalSessionTime * 100), 1)) : 100;
      return { session: totalSessionTime, demandShifting: demandShiftingTime, time: formattedTime };
    });
    return xAxisLabelsHalfHour.map(hour => {
      const existing = existingData.find(el => el.time === hour)
      return existing ? existing : { session: 100, demandShifting: 100, time: hour };
    });
  }

  useEffect(() => {
    getChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar, getEnergyComparison]);


  const handleMinusDay = () => {
    if (calendar.startDate) {
      setCalendar({
        startDate: adjustDate(calendar.startDate, -1),
        endDate: adjustDate(calendar.startDate, -1),
      });
    }
  };

  const getChartData = async () => {
    if (calendar.startDate && calendar.endDate) {
      // generateRandomDataForDate(calendar.startDate);
      const start = new Date(calendar.startDate);
      start.setHours(0, 0, 0, 0);
      const end = new Date(calendar.endDate);
      end.setHours(23, 0, 0, 0);
      await dashboardStore.getDemandShiftingUsage(
        start.toISOString(),
        end.toISOString()
      );
      await dashboardStore.getEnergyComparison(
        start.toISOString(),
        end.toISOString()
      );
      await dashboardStore.getActiveSessions(
        start.toISOString(),
        end.toISOString()
      );
    }
  };

  const handlePlusDay = () => {
    if (calendar.startDate) {
      setCalendar({
        startDate: adjustDate(calendar.startDate, 1),
        endDate: adjustDate(calendar.startDate, 1),
      });
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 items-stretch h-full custom-scrollbar">
        <ContentHeader group="Dashboard" subgroup="Overview">
          <div className="flex gap-5 items-center">
            <p className="text-[16px] font-semibold text-darkGreen whitespace-nowrap">
              Select Date
            </p>
            <div className="relative w-[240px]">
              <DateButton
                showDateModal={showDateModal}
                setShowDateModal={setShowDateModal}
                startDate={calendar.startDate ? convertUTCDateTimeToLocale(calendar.startDate) : null}
                endDate={calendar.endDate ? convertUTCDateTimeToLocale(calendar.endDate) : null}
                demandShift={true}
                handleMinusDay={handleMinusDay}
                handlePlusDay={handlePlusDay}
                dashboard
              />

              {showDateModal && (
                <DemandDateSelect
                  showCalendar={setShowDateModal}
                  start={calendar.startDate}
                  end={calendar.endDate}
                  setSelected={handleSelectedDate}
                  demandShift={true}
                  dashboard
                />
              )}
            </div>
          </div>
        </ContentHeader>
        <div className="flex-1 flex flex-col h-full w-full last:gap-0">
          <div className="bg-white rounded-[10px] pt-5 pb-3 px-6 items-start w-full">
            <DashboardEnergy
              date={formatDemandDateLocale(convertUTCDateTimeToLocale(calendar.startDate!))}
              yRight={formatPriceData(energyComparison.price)}
              yLeft={formatPriceData(energyComparison.realEnergyConsumed)}
            />
          </div>
          <div className="bg-white rounded-[10px] pt-5 pb-3 px-6 mt-3 items-start w-full">
            <DashboardEstimatedEnergy
              date={formatDemandDateLocale(convertUTCDateTimeToLocale(calendar.startDate!))}
              yRight={formatPriceData(energyComparison.price)}
              yLeft={formatPriceData(energyComparison.estimatedEnergyConsumed)}
            />
          </div>
          <div className="bg-white rounded-t-[10px] pt-5 pb-3 px-6 mt-3 ">
            <DashboardDemandChart
              date={formatDemandDateLocale(convertUTCDateTimeToLocale(calendar.startDate!))}
              yValues={formatDemandShiftingData(demandShiftingUsage)}
            />
          </div>
          <div className="bg-white rounded-t-[10px] pt-5 pb-3 px-6 mt-3 ">
            <DashboardActiveSessionsChart
              date={formatDemandDateLocale(convertUTCDateTimeToLocale(calendar.startDate!))}
              yValues={activeSessions.pointDtos.map(point => ({ time: formatDemandShiftingTime(point.date), numberOfSessions: point.numberOfSessions }))}
            />
          </div>
        </div>
      </div>
      <PreloadMap />
    </>
  );
});

export default DashboardOverview;
