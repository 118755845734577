import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useState, useMemo } from 'react';
import { CSVdownload } from '../../../../components/CSVdownload/CSVdownload';
import { downloadCSV } from '../../../../utils/csv/downloadCsv';
import { xAxisLabels } from './XAxisLabels';

// Register Chart.js components for mixed chart type compatibility
ChartJS.register(...registerables);

interface Props {
    date: string,
    yLeft: { dateHour: string, value: number }[]; // estimatedEnergyConsumption
    yRight: { dateHour: string, value: number }[]; // price
}

const EstimatedEnergy = ({
    date,
    yLeft,
    yRight,
}: Props) => {
    const [optionsHovered, setOptionsHovered] = useState(false);

    ChartJS.defaults.color = '#ADADAD';

    // Chart data with explicit dataset types
    const data = {
        labels: xAxisLabels,
        datasets: [
            {
                type: 'bar' as const, // Explicitly specify type
                data: yLeft,
                yAxisID: 'y',
                backgroundColor: "rgba(242, 138, 5, 1)",
                categoryPercentage: 0.9,
                borderRadius: 4,
                parsing: {
                    xAxisKey: 'dateHour',
                    yAxisKey: 'value',
                },
                order: 2,
            },
            {
                type: 'line' as const, // Explicitly specify type
                data: yRight,
                borderColor: '#DA3333',
                fill: false,
                yAxisID: 'y1',
                tension: 0.4,
                borderDash: [3, 1.5],
                parsing: {
                    xAxisKey: 'dateHour',
                    yAxisKey: 'value',
                },
                order: 1
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                type: 'category' as const, // Specify the x-axis as category or linear
                ticks: {
                    callback: function (val: any, index: number) {
                        return index % 2 === 0 ? xAxisLabels[index] : '';
                    },
                    maxRotation: 0,
                    minRotation: 0
                },
                grid: {
                    color: "#1C1C1C0D",
                    offset: false,
                    beginAtZero: true
                },
            },
            y: {
                type: 'linear' as const,
                display: true,
                position: "left" as const,
                min: 0,
                dragData: false,
                grid: {
                    drawOnChartArea: false,
                },
                title: {
                    display: true,
                    text: 'kWh',
                    color: 'rgba(242, 138, 5, 1)',
                    font: {
                        weight: 500,
                    },
                },
            },
            y1: {
                type: 'linear' as const,
                display: true,
                position: "right" as const,
                min: 0,
                dragData: false,
                grid: {
                    drawOnChartArea: false,
                },
                title: {
                    display: true,
                    text: 'EUR/kWh',
                    color: '#DA3333',
                    font: {
                        weight: 500,
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: '#004C41',
                displayColors: false,
                callbacks: {
                    title: () => '',
                    label: function (tooltipItem: any) {
                        return `${tooltipItem.raw.value.toFixed(2)}`;
                    },
                },
            },
        },
    };

    const handleGetEnergyConsumptionCSV = () => {
        try {
            const zip = (
                yLeft: number[],
                yRight: number[],
                x: string[]
            ) =>
                yLeft.map((yVal, idx) => ({
                    estimatedEnergyConsumptionkWh: yVal,
                    price: yRight[idx],
                    date: date,
                    time: x[idx],
                }));
            downloadCSV(zip(yLeft.map(el => el.value), yRight.map(el => el.value), yLeft.map(el => el.dateHour)), "EstimatedEnergyDispersed-" + date);
        } catch (err) {
            console.log("Error while mapping data for download csv: ", err);
        }
    };

    return (
        <div className="flex flex-grow flex-col gap-6 w-full">
            <div className="flex justify-end items-center w-[100%]">
                <div className="flex items-center gap-5 relative">
                    <div className="flex gap-5">
                        <div className="flex items-center gap-2 text-[12px] text-darkGreen">
                            <img alt="" src="/assets/svg/chart-dots/orange.svg" />
                            Estimated energy consumption without demand shifting
                        </div>
                        <div className="flex items-center gap-2 text-[12px] text-[#515151]">
                            <div className="border-b-2 border-dotted border-[#DA3333] w-6"></div>
                            Electricity Price
                        </div>
                    </div>
                    <div className="relative cursor-pointer">
                        <img
                            src={
                                optionsHovered
                                    ? "/assets/svg/dots/dotsActive.svg"
                                    : "/assets/svg/dots/dots.svg"
                            }
                            alt=""
                            className="size-7"
                            onClick={() => setOptionsHovered((prev) => !prev)}
                        />
                        {optionsHovered && (
                            <div
                                style={{ boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.07)" }}
                                className="absolute top-7 right-0"
                            >
                                <CSVdownload
                                    handleDownloadCSV={handleGetEnergyConsumptionCSV}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div>
                <Chart type="bar" data={data} options={options} className="h-[330px] w-full" />
            </div>
        </div>
    );
};

export default EstimatedEnergy;
