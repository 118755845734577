import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useState } from "react";
import { CSVdownload } from "../../../../components/CSVdownload/CSVdownload";
import { downloadCSV } from "../../../../utils/csv/downloadCsv";
import { xAxisLabels } from "./XAxisLabels";

interface Props {
    date: string;
    xValues?: string[];
    yValues: { numberOfSessions: number, time: string }[];
}

const DashboardActiveSessionsChart = ({
    date,
    xValues = xAxisLabels,
    yValues,
}: Props) => {
    const [optionsHovered, setOptionsHovered] = useState(false);


    const data = {
        labels: xValues,
        datasets: [
            {
                data: yValues,
                label: "",
                backgroundColor: "#A2B5CC",
                categoryPercentage: 0.9,
                borderRadius: 4,
                parsing: {
                    xAxisKey: 'time',
                    yAxisKey: 'numberOfSessions',
                },
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                dragData: false,
                stacked: true,
                grid: {
                    color: "#1C1C1C0D",
                    offset: false,
                    beginAtZero: true
                },
                ticks: {
                    callback: function (val: any, index: number) {
                        return index % 2 === 0 ? xAxisLabels[index] : '';
                    },
                    maxRotation: 0,
                    minRotation: 0
                },
            },
            y: {
                dragData: false,
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    callback: function (tickValue: string | number) {
                        if (typeof tickValue === "number") {
                            if (Math.floor(tickValue) === tickValue) {
                                return tickValue;
                            }
                        }
                        return null;
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
                onHover: function (e: any) {
                    e.target.style.cursor = "pointer";
                },
            },
            tooltip: {
                displayColors: false,
                padding: {
                    top: 6,
                    bottom: 6,
                    left: 8,
                    right: 8,
                },
                callbacks: {
                    title: () => "",
                    label: function (tooltipItem: any) {
                        return tooltipItem.raw.numberOfSession;
                    },
                    labelColor: function (context: any) {
                        return {
                            backgroundColor: "#ABBED6",
                            borderColor: "#ABBED6",
                        };
                    },
                    backgroundColor: function (tooltipItem: any) {
                        return "#ABBED6";
                    },
                },
            },
        },
        onHover: (event: any, activeElements: any) => {
            (event?.native?.target as HTMLElement).style.cursor =
                activeElements?.length > 0 ? "pointer" : "auto";
        },
    };

    const handleDownloadCSV = async () => {
        try {
            const zip = (y: number[], x: string[]) =>
                y.map((yVal, idx) => ({
                    activeSessionsCount: yVal,
                    date: date,
                    time: x[idx],
                }));
            downloadCSV(zip(yValues.map(el => el.numberOfSessions), yValues.map(el => el.time)), "ActiveSessionsNumber-" + date);
        } catch (err) {
            console.log("Error while mapping data for download csv: ", err);
        }
    };

    return (
        <div className="flex flex-col gap-6 w-full">
            <div className="flex justify-between items-center w-[100%]">
                <div className="text-[14px] font-semibold text-darkGreen">
                    Active sessions
                </div>
                <div className="flex items-center gap-5 relative">
                    <div className="flex gap-2">
                        <div className="flex text-[12px] text-darkGreen">
                            <img alt="" src="/assets/svg/chart-dots/lightBlue.svg" />
                            Number of sessions
                        </div>
                    </div>
                    <div className="relative cursor-pointer">
                        <img
                            src={
                                optionsHovered
                                    ? "/assets/svg/dots/dotsActive.svg"
                                    : "/assets/svg/dots/dots.svg"
                            }
                            alt=""
                            className="size-7"
                            onClick={() => setOptionsHovered((prev) => !prev)}
                        />
                        {optionsHovered && (
                            <div
                                style={{ boxShadow: "0px 14px 29.6px 0px rgba(0, 0, 0, 0.07)" }}
                                className="absolute top-7 right-0"
                            >
                                <CSVdownload handleDownloadCSV={handleDownloadCSV} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="w-full pl-4 pr-10">
                <Bar data={data} options={options} className="h-[240px] w-full" />
            </div>
        </div>
    );
};

export default DashboardActiveSessionsChart;
