import { useEffect, useState } from "react";
import TextInput from "../../../components/TextInput/TextInput";
import AddressInput from "../../../components/AddressInput/AddressInput";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import CountrySelect from "../../../components/CountrySelect/CountrySelect";
import { Company } from "../../../app/models/user";

const CompanyInfo = observer(() => {
  const { userStore, commonStore } = useStore();
  const { company } = userStore;
  const initialCompanyName = "MVM LLC";
  const [companyName, setCompanyName] = useState(initialCompanyName);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [editedCompany, setEditedCompany] = useState<Company>(
    {
      id: 0,
      name: "",
      supportEmail: "",
      street: "",
      city: "",
      postalNumber: "",
      countryId: 0,
      country: "",

    });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedCompany((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddressChange = async (value: string) => {
    setEditedCompany((prev) => ({
      ...prev,
      street: value,
    }));
  };

  const handleCountrySelect = (value: number, label: string) => {
    setEditedCompany((prev) => ({
      ...prev,
      countryId: value,
      country: label,
    }));
  };
  useEffect(() => {
    const fetchData = async () => {
      commonStore.getCountriesForDropdown();
      const res = await userStore.getCompanyInfo();
      setEditedCompany(res);
    }
    fetchData();
    return (() => { userStore.clearCompanyInfo() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userStore.company && editedCompany && JSON.stringify(userStore.company) !== JSON.stringify(editedCompany))
      setIsSaveEnabled(true);
    else
      setIsSaveEnabled(false);
  }, [editedCompany, userStore.company]);

  const handleSaveCompanyChanges = async () => {
    if (isSaveEnabled) {
      await userStore.updateCompanyInfo(editedCompany);
      const res = await userStore.getCompanyInfo();
      setEditedCompany(res);
    }
  }


  return (
    <div className="flex flex-col gap-8 pt-10 pl-7 pr-7">
      <div className="flex justify-between items-center">
        <p className="text-[20px] font-semibold text-darkGreen">
          Company Information
        </p>
        <div className="flex gap-3.5">
          <button
            onClick={handleSaveCompanyChanges}
            className={`${isSaveEnabled
              ? "bg-light-green border-light-green text-[#FDFDFD]"
              : "border-input-border text-lightGray"
              } flex w-[186px] justify-center border-[2px] py-2.5 px-3 items-center rounded-[10px] gap-2 text-[14px] font-medium`}
            disabled={!isSaveEnabled}
          >
            Save
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-7 justify-center">
        <div className="flex gap-8">
          <div className="flex flex-col gap-2 flex-1">
            <label className="text-[14px] font-bold text-darkGreen">
              Company Name
            </label>
            <TextInput
              placeholder="Company Name"
              name="name"
              value={editedCompany.name || ""}
              onChange={handleChange}
              readonly={false}
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <label className="text-[14px] font-bold text-darkGreen">
              Support Email
            </label>
            <TextInput
              placeholder="Support Email"
              name="supportEmail"
              value={editedCompany.supportEmail}
              onChange={handleChange}
              readonly={false}
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <label className="text-[14px] font-bold text-darkGreen">
              Street
            </label>
            <AddressInput
              value={editedCompany.street}
              setValue={handleAddressChange}
            />
          </div>
        </div>
        <div className="flex gap-8">
          <div className="flex flex-col gap-2 flex-1">
            <label className="text-[14px] font-bold text-darkGreen">City</label>
            <TextInput
              placeholder="City"
              name="city"
              value={editedCompany.city}
              onChange={handleChange}
              readonly={false}
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <label className="text-[14px] font-bold text-darkGreen">
              Postal Number
            </label>
            <TextInput
              placeholder="Postal Number"
              name="postalNumber"
              value={editedCompany.postalNumber}
              readonly={false}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <label className="text-[14px] font-bold text-darkGreen">
              Country
            </label>
            <CountrySelect
              value={editedCompany.country}
              handleSelect={(id: number, internationalName: string) => handleCountrySelect(id, internationalName)}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default CompanyInfo;
