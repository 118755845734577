import { useState, useEffect } from "react";
import Modal from "../../../components/Modal/Modal";
import { useStore } from "../../../app/stores/store";

interface Props {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}

const ChangePassword = ({ modalOpen, setModalOpen }: Props) => {
  const { userStore } = useStore();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState<string>("");
  const [weakPassword, setWeakPassword] = useState<boolean>(true);
  const [okPassword, setOkPassword] = useState<boolean>(false);
  const [strongPassword, setStrongPassword] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  const handlePasswordComplexity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setNewPassword(password);

    if (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password)
    ) {
      if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
        setWeakPassword(false);
        setOkPassword(false);
        setStrongPassword(true);
        return;
      }
      setWeakPassword(false);
      setStrongPassword(false);
      setOkPassword(true);
    } else {
      setWeakPassword(true);
      setOkPassword(false);
      setStrongPassword(false);
    }
  };

  useEffect(() => {
    if (newPassword && repeatedPassword && newPassword === repeatedPassword) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [newPassword, repeatedPassword]);

  const handleChangePassword = async () => {
    if (isActive) {
      await userStore.changePassword({
        oldPassword: oldPassword,
        newPassword: newPassword,
        newPasswordRepeated: repeatedPassword
      });
      setModalOpen(false);
    }
  }

  return (
    <Modal
      isVisible={modalOpen}
      onClose={() => setModalOpen(false)}
      modalWidth="w-[540px]"
    >
      <div className="flex flex-col gap-8 px-8 py-10">
        <p className="text-[20px] font-semibold text-darkGreen mb-4">
          Change password
        </p>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2.5 flex-1">
            <label className="text-[14px] font-bold text-darkGreen">
              Old Password
            </label>
            <input
              className="rounded-[10px] bg-white text-[14px] font-medium focus:border-light-green focus:outline-none border-[2px] border-solid border-[#E6E6E6] bg-white py-[14px] px-[10px]"
              placeholder="Old Password"
              value={oldPassword}
              name="oldPassword"
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2.5 flex-1">
            <label className="text-[14px] font-bold text-darkGreen">
              New Password
            </label>
            <input
              className="rounded-[10px] bg-white text-[14px] font-medium focus:border-light-green focus:outline-none border-[2px] border-solid border-[#E6E6E6] bg-white py-[14px] px-[10px]"
              placeholder="New Password"
              value={newPassword}
              name="newPassword"
              onChange={handlePasswordComplexity}
            />

            <div className="flex h-2 flex-row justify-between items-center flex-wrap">
              <div className="flex flex-row justify-start gap-1 items-center">
                <div
                  className={`w-16 h-1 rounded-full ${!newPassword
                    ? "bg-[#F2F2F2]"
                    : weakPassword
                      ? "bg-[#DA3333]"
                      : okPassword
                        ? "bg-[#F28A05]"
                        : "bg-[#008480]"
                    }`}
                />
                <div
                  className={`w-16 h-1 rounded-full ${!newPassword
                    ? "bg-[#F2F2F2]"
                    : weakPassword
                      ? "bg-[#F2F2F2]"
                      : okPassword
                        ? "bg-[#F28A05]"
                        : strongPassword
                          ? "bg-[#008480]"
                          : "bg-[#F2F2F2]"
                    }`}
                />
                <div
                  className={`w-16 h-1 rounded-full ${!newPassword
                    ? "bg-[#F2F2F2]"
                    : weakPassword
                      ? "bg-[#F2F2F2]"
                      : strongPassword
                        ? "bg-[#008480]"
                        : "bg-[#F2F2F2]"
                    }`}
                />
              </div>
              <div
                className={`text-[14px] font-medium ${!newPassword
                  ? "text-white"
                  : weakPassword
                    ? "text-[#DA3333]"
                    : okPassword
                      ? "text-[#F28A05]"
                      : strongPassword
                        ? "text-[#008480]"
                        : ""
                  }`}
              >
                {weakPassword
                  ? "Too Weak"
                  : okPassword
                    ? "Could be Stronger"
                    : "Strong Password"}
              </div>
            </div>

            <p className="pl-2 text-[12px] text-[#A6A6A6] mt-2">
              Password must be 8-16 characters long, and contain one uppercase
              and one lowercase character.
            </p>
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <label className="text-[14px] font-bold mb-[2px] text-darkGreen">
              Repeated Password
            </label>
            <input
              className="rounded-[10px] bg-white text-[14px] font-medium focus:border-light-green focus:outline-none border-[2px] border-solid border-[#E6E6E6] bg-white py-[14px] px-[10px]"
              placeholder="Repeated Password"
              value={repeatedPassword}
              name="repeatedPassword"
              onChange={(e) => setRepeatedPassword(e.target.value)}
            />
            <label className="pl-2 text-[12px] text-[#A6A6A6]">
              Both passwords must match
            </label>
          </div>
        </div>
        <button
          disabled={!isActive}
          onClick={handleChangePassword}
          className={`w-full mt-6 rounded-10 h-12 flex justify-center items-center text-[14px] font-semibold ${isActive
            ? "text-white bg-light-green"
            : "text-[#515151] bg-[#E9E9E9]"
            }`}
        >
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default ChangePassword;
